import React from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Menu from '../components/menu';
import Layout from "../components/layout";

export default () => {
	return (
		<Layout>
			<div className="jumbotron">
        <Link to="/extras" className="closeX"><span>X</span></Link>
        <Menu localPath="/software" />
        <h1>Software</h1>
      </div>
      <div className="gridBox">
        <div className="gridFraction"> 
          <a href="/data/xtras/milliongame.zip"><img src="/images/Screenshot-Player.png" /></a>
        </div>
        <div className="gridFraction">
          <div>
          	Million-Game Player und Editor (Freeware) zum Spielen und Erstellen von Fragespielen. Benötigt das kostenlose <a href="https://de.wikipedia.org/wiki/.NET" target="_blank">.NET Framework 4.0</a> oder höher von Microsoft. Mit dem Million-Game Player können Fragespiele (nach dem Schema von <a href="http://de.wikipedia.org/wiki/Wer_wird_Million%C3%A4r%3F" target="_blank">diesem</a> Spiel) gespielt werden. Auch in diesem Spiel finden Sie drei Joker (50:50, Publikum, Telefon). Mit dem Million-Game Editor können Sie solche Spiele ganz einfach und ohne Programmierkenntnisse zu einem Thema ihrer Wahl herstellen.
          </div>
        </div>
        <div className="gridFraction"> 
          <a href="/data/xtras/ekse.zip"><img src="/images/Screenshot-Ekse.png" /></a>
        </div>
        <div className="gridFraction">
          <div>
            <span style={{ 'fontWeight': 'bolder'}}>EKSE − Der <span style={{'color': 'red'}}>E</span>rinnerungs-<span style={{color: 'red'}}>K</span>arten-<span style={{color: 'red'}}>S</span>piel-<span style={{color: 'red'}}>E</span>rsteller</span><br />
            (Da sich in dem Ordner eine ausführbare Datei befindet, wird ihr Virenscanner wahrscheinlich beim Download Alarm schlagen. EKSE ist ein portables Programm und nimmt keine Änderungen an ihrem System vor.) Benötigt das kostenlose <a href="https://de.wikipedia.org/wiki/.NET" target="_blank">.NET Framework 3.5</a> oder höher von Microsoft.<br /><br/>
            Mit diesem Editor können Sie Spiele wie die folgenden selbst herstellen:
            <ul>
            	<li><a href="/data/xtras/LudwigVanBeethoven.zip">Das Beethoven-Spiel</a></li>
            	<li><a href="/data/xtras/Komponisten.zip">Das Komponisten-Spiel</a></li>
            </ul>
            Diese und weitere Spiele zum Online-Spielen finden Sie <a href="https://musikanalyse.net/spielen/">hier</a>.
          </div>
        </div>
        <div className="gridFraction">
          <Link to="https://kaiser-ulrich.de/wellenwriter/home.html"><img src="/images/wellenwriter.png" /></Link> 
        </div>
        <div className="gridFraction">
          <div>
            
          </div>
          <div>
            <span style={{ 'fontWeight': 'bolder'}}>WellenWriter</span><br />
            Ein nützliches Tool zum Visualisieren des Lautstärkeverlaufs eines Sound- oder Videodatei, das zudem ein schnelles und intuitives Navigieren in der Sounddatei ermöglicht. WellenWriter verwendet <a href="https://github.com/katspaugh/wavesurfer.js" target="_blank">wavesurfer.js von Alex Khokhulin</a> (lizensiert unter CC BY). Den WellenWriter, der nur im neueren Chrome- oder Firefox-Browsern funktioniert, können Sie sich <a href="/data/xtras/wellenwriter.zip">hier</a> herunterladen und nach dem entzippen auch offline verwenden. 
          </div>
        </div>
      </div>
      <Helmet>
        <title>OER | Ulrich Kaiser - Warum?</title>
        <meta name="description" content="Software für den Musikunterricht, die im Zusammenhang mit den OpenBooks (OER) von Ulrich Kaiser stehen." />
        <meta name="keywords" content="Software, OER, Open Educational Resources, OpenBooks" />
        <meta name="copyright" content="Ulrich Kaiser, 2011" />
      </Helmet>
		</Layout>
	)
}